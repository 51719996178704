<template>
  <div class="box t-center">
    <h1>Hello！{{ userName }}</h1>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      userName: localStorage.getItem("username"),
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
}
</style>
